import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormRendererService } from "src/services/formrenderer.service";

@Component({
  selector: "app-renderer",
  templateUrl: "./renderer.component.html",
  styleUrls: ["./renderer.component.scss"]
})
export class RendererComponent implements OnInit, OnChanges {
  @Input('data_render') JSONData;
  public formRendererData: any;
  public formRendererValue: any = {};
  public routeparams: any = [];
  formData: any;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _formrenderer: FormRendererService
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.JSONData && changes.JSONData.currentValue) {
      this.formData = changes.JSONData.currentValue;
      this.formRendererData = ({ components: JSON.parse(changes.JSONData.currentValue) });
      console.log(this.formRendererData);
      // this.formRendererValue = { data: JSON.parse(changes.JSONData.currentValue) };
    }
  }
  ngOnInit() {
    this.routeparams = this._route.params.subscribe(params => {
      if (params && params.id) {
        this._formrenderer.GetTemplateById({ TemplateId: params.id }).toPromise()
        .then(data => {
          this.formData = data;
          this.formRendererData = ({ components: JSON.parse(data[0].FormDataContentJson) });
        });
      }
    });
  }

  onSubmit(submission: any) {
    let params = {
      data: JSON.stringify({
        templateid: this._route.snapshot.params.id,
        data: JSON.stringify(submission.data)
      })
    };
    this._formrenderer
      .SaveFormData(params)
      .toPromise()
      .then((result: any) => {
        this._router.navigate(["/savedforms"]);
      })
      .catch(err => {
        console.log(err);
      });
  }
  Draft(event) {
    console.log(event);
  }
}
