import { Injectable } from '@angular/core';
import { DataFactoryService } from 'src/services/api.service';
import { ApiUrl } from 'src/utilities/server-urls';
import { RequestParams } from 'src/services/request-params';
// Categories
const categories = [
  {
    name: 'material2',
    type: 'folder',
    children: [
      {
        name: 'src',
        type: 'folder',
        children: [
          {
            name: 'cdk',
            type: 'folder',
            children: [
              { name: 'package.json', type: 'file' },
              { name: 'BUILD.bazel', type: 'file' },
            ]
          },
          { name: 'lib', type: 'folder' }
        ]
      }
    ]
  },
  {
    name: 'angular',
    type: 'folder',
    children: [
      {
        name: 'packages',
        type: 'folder',
        children: [
          { name: '.travis.yml', type: 'file' },
          { name: 'firebase.json', type: 'file' }
        ]
      },
      { name: 'package.json', type: 'file' }
    ]
  },
  {
    name: 'angularjs',
    type: 'folder',
    children: [
      { name: 'gulpfile.js', type: 'file' },
      { name: 'README.md', type: 'file' }
    ]
  },
  {
    PkFormCategoriesId: 1,
    CategoryName: "HealthCare",
    type: 'folder',
    CatTemplateCount: 4,
    FSC: [
      {
        PkFormSubCategoriesId: 1,
        SubCategoryName: "Clinical",
        SubCatTemplateCount: 2
      },
      {
        PkFormSubCategoriesId: 2,
        SubCategoryName: "Managerial",
        SubCatTemplateCount: 2
      }
    ]
  }
];
@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private _datafactory: DataFactoryService, private requestParams: RequestParams) { }
  getCategories(params?) {
    return this._datafactory.post(ApiUrl.GetCategories, params).toPromise();
  }
  SaveSubcategory(params?) {
    return this._datafactory.post(ApiUrl.SaveSubcategory, this.requestParams.ConstructRequest(params)).toPromise();
  }
}
