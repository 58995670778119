import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormTemplateService } from 'src/services/formtemplates.service';

@Component({
  selector: 'app-formtype',
  templateUrl: './formtype.component.html',
  styleUrls: ['./formtype.component.scss']
})
export class FormtypeComponent implements OnInit, OnChanges {
  @Output() triggerClick = new EventEmitter();
  @Input('preSelect') preSelect;
  formTypes = [];
  constructor(private formtempService: FormTemplateService) { }
  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.preSelect && simpleChanges.preSelect.currentValue) {
      if (this.formTypes && this.formTypes.length) {
        const type = this.formTypes.filter(type => type.PKFormTypeId === simpleChanges.preSelect.currentValue.typeId);
        if (type && type.length) {
          this.triggerCheckEvent(null, type[0]);
        }
      }
    }
  }
  ngOnInit() {
    this.formtempService.GetFormTypes()
    .subscribe(d => {
      if (d && d.length) {
        this.formTypes = d.map(type => {
          type['selected'] = false;
          return type;
        });
      }
    });
  }
  triggerCheckEvent(event, type) {
    if (type) {
      type.selected = true;
    }
    // tslint:disable-next-line: max-line-length
    setTimeout(() => this.triggerClick.emit({FormType: type}), 0);
  }
}
