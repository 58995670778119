import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { FormioModule } from 'angular-formio';
import { FormioGrid } from 'angular-formio/grid';

import { AppComponent } from './app.component';
import { BuilderComponent } from './forms/builder/builder.component';
import { RendererComponent } from './forms/renderer/renderer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TemplatesComponent } from './forms/templates/templates.component';
import { SavedFormsComponent } from './forms/saved-forms/saved-forms.component';

import { PrismService } from './Prism.service';
import { DataFactoryService } from 'src/services/api.service';
import { FormTemplateService } from 'src/services/formtemplates.service';
import { FormBuilderService } from 'src/services/formbuilder.service';
import { FormRendererService } from 'src/services/formrenderer.service';
import { SavedFormService } from 'src/services/savedform.service';
import { HeaderComponent } from './shared/header/header.component';
import { ShareDataService } from 'src/services/sharedata.service';
import { CategoriesComponent } from './shared/components/categories/categories.component';
import { TenantsComponent } from './shared/components/tenants/tenants.component';
import { TagsComponent } from './shared/components/tags/tags.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule, MatIconModule, MatButtonModule, MatCheckboxModule, MatPaginatorModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { ViewerModalComponent } from './shared/components/viewer-modal/viewer-modal.component';
import { RequestParams } from 'src/services/request-params';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormtypeComponent } from './shared/components/formtype/formtype.component';

const Formio = require('formiojs').Formio;
Formio.icons = 'fontawesome';

@NgModule({
  declarations: [
    AppComponent,
    TemplatesComponent,
    SavedFormsComponent,
    BuilderComponent,
    RendererComponent,
    PageNotFoundComponent,
    HeaderComponent,
    CategoriesComponent,
    TenantsComponent,
    TagsComponent,
    ViewerModalComponent,
    FormtypeComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    CommonModule,
    FormioModule,
    FormioGrid,
    BrowserAnimationsModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [PrismService,
    DataFactoryService,
    RequestParams,
    FormTemplateService,
    FormBuilderService,
    FormRendererService,
    SavedFormService,
    ShareDataService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
