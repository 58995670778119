import { Component, OnInit } from "@angular/core";
import { SavedFormService } from "src/services/savedform.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-saved-forms",
  templateUrl: "./saved-forms.component.html",
  styleUrls: ["./saved-forms.component.scss"]
})
export class SavedFormsComponent implements OnInit {
  items: any;

  constructor(private _savedform: SavedFormService, private _router: Router) {}

  ngOnInit() {
    this._savedform
      .SavedFormList()
      .toPromise()
      .then((result: any) => {
        this.items = result;
      })
      .catch(err => {
        console.log(err);
      });
  }

  public gotoSavedFormDetails(url, id) {
    id = id.split(".")[0]+'&saved';
    this._router.navigate([url, id]);
  }
}
