const Formio = require('formiojs').Formio;

/**
 * Get the input component class by referencing Formio.Components.components map.
 */
var InputComponent = Formio.Components.components.input;

/**
 * Create a new LinkFormComponent "class" using ES5 class inheritance notation. 
 * https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance
 * 
 * Here we will derive from the base component which all Form.io form components derive from.
 *
 * @param component
 * @param options
 * @param data
 * @constructor
 */
export function LinkFormComponent(component, options, data) {
  InputComponent.prototype.constructor.call(this, component, options, data);
}

// Perform typical ES5 inheritance
LinkFormComponent.prototype = Object.create(InputComponent.prototype);
LinkFormComponent.prototype.constructor = LinkFormComponent;

/**
 * Define what the default JSON schema for this component is. We will derive from the InputComponent
 * schema and provide our overrides to that.
 * @return {*}
 */
LinkFormComponent.schema = function() {
  return InputComponent.schema({
    type: 'linkForm',
    numRows: 3,
    numCols: 3
  });
};

/**
 * Register this component to the Form Builder by providing the "builderInfo" object.
 * 
 * @type {{title: string, group: string, icon: string, weight: number, documentation: string, schema: *}}
 */
LinkFormComponent.builderInfo = {
  title: 'Link Existing Form',
  group: 'basic',
  icon: 'fa fa-link',
  weight: 70,
  documentation: 'http://help.form.io/userguide/#table',
  schema: LinkFormComponent.schema()
};

/**
 *  Tell the renderer how to render this component.
 */
LinkFormComponent.prototype.render = function(element) {
  // var tpl = '<div class="table-responsive">';
  // tpl += this.renderTemplate('label', {
  //   label: this.labelInfo,
  //   component: this.component,
  //   element: element,
  //   tooltip: this.interpolate(this.component.tooltip || '').replace(/(?:\r\n|\r|\n)/g, '<br />'),
  // });
  // tpl += '<table class="table">';
  // tpl += '<tbody>';
  // for (let i = 0; i < this.component.numRows; i++) {
  //   tpl += '<tr>';
  //   for (let j = 0; j < this.component.numCols; j++) {
  //     tpl += '<td>';        
  //     tpl += this.renderTemplate('input', {
  //       input: {
  //         type: 'input',
  //         attr: {
  //           type: 'checkbox'
  //         },
  //         id: 'check-' + i + '-' + j
  //       }
  //     });
  //     tpl += '</td>';
  //   }
  //   tpl += '</tr>';
  // }
  // tpl += '</tbody>';
  // tpl += '</table>';
  // tpl += '</div>';
  // return tpl;
};

/**
 * Provide the input element information. Because we are using checkboxes, the change event needs to be 
 * 'click' instead of the default 'change' from the InputComponent.
 * 
 * @return {{type, component, changeEvent, attr}}
 */
LinkFormComponent.prototype.elementInfo = function() {
  const info = InputComponent.prototype.elementInfo.call(this);
  info.changeEvent = 'click';
  return info;
};

/**
 * Tell the renderer how to "get" a value from this component.
 * 
 * @return {Array}
 */
LinkFormComponent.prototype.getValue = function() {
  var value = [];
  if (!this.refs.input || !this.refs.input.length) {
    return value;
  }
  for (let i = 0; i < this.component.numRows; i++) {
    value[i] = [];
    for (let j = 0; j < this.component.numCols; j++) {
      var index = (i * this.component.numCols) + j;
      if (this.refs.input[index]) {
        value[i][j] = !!this.refs.input[index].checked;
      }
    }
  }
  return value;
};

/**
 * Tell the renderer how to "set" the value of this component.
 * 
 * @param value
 * @return {boolean}
 */
LinkFormComponent.prototype.setValue = function(value) {
  var changed = InputComponent.prototype.updateValue.call(this, value);
  if (!value) {
    return changed;
  }
  for (let i = 0; i < this.component.numRows; i++) {
    if (!value[i]) {
      break;
    }
    for (let j = 0; j < this.component.numCols; j++) {
      if (!value[i][j]) {
        return false;
      }
      let checked = value[i][j] ? 1 : 0;
      var index = (i * this.component.numCols) + j;
      this.refs.input[index].value = checked;
      this.refs.input[index].checked = checked;
    }
  }
  return changed;
};

// Use the table component edit form.
// LinkFormComponent.editForm = Formio.Components.components.table.editForm;
LinkFormComponent.editForm = function(event) {
  console.log(document.getElementsByClassName('formio-dialog-close'))
  for (let i = 0; i < document.getElementsByClassName('formio-dialog-close').length; i++) {
    console.log(document.getElementsByClassName('formio-dialog-close')[i]);
  }
  setTimeout(() =>document.getElementsByClassName('formio-dialog-close').forEach(el => el.click()) ,0);
};
