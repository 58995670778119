import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerHost } from '../utilities/server-urls';

@Injectable({
    providedIn: 'root'
})
export class DataFactoryService {

    httpHeaders: HttpHeaders;
    test: Observable<any>
    constructor(private _httpClient: HttpClient) {
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    get(url: string): Observable<any> {
        return this._httpClient.get(url);
    }

    /**
     * post() is for handle http post request.
     * @param url
     * @param requestBody
     */
    post(url: string, requestBody: any): Observable<any> {
        return this._httpClient.post(url, requestBody, {
            headers: this.httpHeaders,
            responseType: 'json'
        });
    };
}