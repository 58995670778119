import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class ShareDataService {
  private dataSource = new Subject<any>();
  sharedData$ = this.dataSource.asObservable();
  constructor() {}

  setDataObservable(data: any) {
    this.dataSource.next(data);
  }
}
