import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { PrismService } from 'src/app/Prism.service';
import { FormBuilderService } from 'src/services/formbuilder.service';
import { ShareDataService } from 'src/services/sharedata.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FormRendererService } from 'src/services/formrenderer.service';
import { Router } from '@angular/router';
import {LinkFormComponent} from './custom-components/linkForm';
// import { Formio } from 'formiojs';
// const Formio = require('formiojs').Formio;
// Formio.use(MyPlugin);



@Component({
  selector: 'app-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.scss']
})
export class BuilderComponent implements AfterViewInit {
  headerData = {
    title: "Dynamic Form ffTemplates",
    description: "Search your template using categories and tags..."
  };
  linkForm;
  @ViewChild('json', { static: true }) jsonElement?: ElementRef;
  @ViewChild('code', { static: true }) codeElement?: ElementRef;
  @ViewChild('formModalBtn', { static: true }) formModalBtn?: ElementRef;
  @ViewChild('linkFormModalBtn', { static: true }) linkFormModalBtn?: ElementRef;
  @ViewChild('linkFormModalCloseBtn', { static: true }) linkFormModalCloseBtn?: ElementRef;
  @ViewChild('closeModal', { static: true }) closeModal?: ElementRef;
  @ViewChild('formCtrl', {static: false}) formCtrl;
  public form: Object;
  templateData: FormGroup;
  TBitMap: any;
  categoriesSelected: any;
  isSubmit: boolean;
  formTypeId = null;
  preSelectCategoty: { catId: any; };
  preSelectTenant: { tenantId: number; };
  preSelectType: { typeId: number; };

  constructor(public prism: PrismService,
    private _formbuilder: FormBuilderService,
    public formBuilder: FormBuilder,
    public _router: Router,
    private _formrenderer: FormRendererService,
    public _shareDataService: ShareDataService) {
    this.form = { components: [] };
    _shareDataService.setDataObservable(this.headerData);
    this.templateData = this.formBuilder.group({
      TemplateName: ['', Validators.required],
      TemplateDesc: [''],
      FormContentJson: ['', Validators.required],
      SubCategoryIdList: [''],
      AppTenantBitMap: [''],
      FormTypeId: ['']
    });
    this.templateData.valueChanges.subscribe(changes => console.log(changes));
    this.registerComp();
  }

  onChange(event) {
    this.jsonElement.nativeElement.innerHTML = '';
    this.jsonElement.nativeElement.appendChild(document.createTextNode(JSON.stringify(event.form, null, 4)));
    this.templateData.get('FormContentJson').patchValue(JSON.stringify(event.form.components, null, 4));
    this.isSubmit = false;
  }

  ngAfterViewInit() {
    this.prism.init();
    console.log(history);
    if (history.state.templateId) {
      this._formrenderer.GetTemplateById({ TemplateId: history.state.templateId }).toPromise()
        .then(data => {
          // this.formData = data;
          this.templateData.controls['TemplateName'].patchValue(data[0].FormTemplateName);
          this.templateData.controls['TemplateDesc'].patchValue(data[0].FormTemplateDesc);
          this.templateData.get('FormContentJson').patchValue(data[0].FormDataContentJson);
          this.form = ({ components: JSON.parse(data[0].FormDataContentJson) });
          this.preSelectCategoty = {catId: data[0].FkSubCategoryId};
          this.preSelectTenant = {tenantId: data[0].AppTenantBitMap};
          if (data[0].FkFormTypeId) {
            this.preSelectType = {typeId: +data[0].FkFormTypeId};
          }
        });
    }
    console.log(this.formCtrl);
    this.formCtrl.change.subscribe(d => {
      console.log(this.formCtrl);
      if (d.component.type === 'linkForm') {
        this.getAlreadyCreatedForm();
      }
    });

  }
  get nameCtrl() {
    return this.templateData.get('TemplateName');
  }
  get JSONCtrl() {
    return this.templateData.get('FormContentJson');
  }

  saveForm() {
    this.isSubmit = true;
    if (this.TBitMap && this.categoriesSelected) {
      this.templateData.value.AppTenantBitMap = this.TBitMap;
      this.templateData.value.SubCategoryIdList = this.categoriesSelected;
      this.templateData.value.FormTypeId = this.formTypeId;
      console.log(this.templateData);
      if (this.templateData.valid) {
        console.log(this.templateData.value);
        this._formbuilder.SaveDefaultTemplate({...this.templateData.value,
          FormTemplateId: history.state.templateId ? history.state.templateId : null}).toPromise().then((result: any) => {
          this.templateData.reset();
          this.isSubmit = false;
          this.closeModal.nativeElement.click();
        }).catch(err => { console.log(err) });
      }
    } else {
      alert('Please Select Category and Tenant.');
    }
  }
  onSaveForm() {
    this.isSubmit = true;
    if (this.templateData.value.FormContentJson) {
      this.isSubmit = false;
      this.formModalBtn.nativeElement.click();
    }
  }
  getTemplates(event) {
    console.log(event);
    if (event && event.length) {
      const data = event.map(temp => temp.PkFormSubCategoriesId);
      console.log(data);
      this.categoriesSelected = data.filter(d => d !== null).join(',');
    }
  }
  getActiveTenantId(event) {
    console.log(event);
    this.TBitMap = event.bitMapSum;
  }
  getAlreadyCreatedForm() {
    this.linkForm = { link: true};
    this.linkFormModalBtn.nativeElement.click();
  }
  pushComponents(event) {
    console.log(event);
    this._formrenderer.GetTemplateById({ TemplateId: event.PkFormTemplateId }).toPromise()
    .then(data => {
      let manupulatedForm = [];
      if (this.templateData.value.FormContentJson) {
        if (event.checked) {
          manupulatedForm = [...JSON.parse(data[0].FormDataContentJson), ...JSON.parse(this.templateData.value.FormContentJson) ];
        } else {
          manupulatedForm = [...JSON.parse(this.templateData.value.FormContentJson), ...JSON.parse(data[0].FormDataContentJson) ];
        }
      } else {
        manupulatedForm = JSON.parse(data[0].FormDataContentJson);
      }
      this.form = ({ components:  manupulatedForm });
    });
    this.linkFormModalCloseBtn.nativeElement.click();
    // console.log(manupulatedForm);
  }
  linkingResponse(event) {
    console.log(event);
    this.pushComponents(event);
  }
  registerComp() {
    import('formiojs').then((formio: any) => formio.Formio.registerComponent('linkForm', LinkFormComponent) );
  }
  getActiveFormTypeId(event) {
    console.log(event);
    if (event && event.FormType) {
      this.formTypeId = event.FormType.PKFormTypeId;
    } else {
      this.formTypeId = null;
    }
  }
}
