import { Component } from "@angular/core";
import { ShareDataService } from "src/services/sharedata.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent {
  headerData = {
    title: "",
    description: ""
  };

  constructor(public _shareDataService: ShareDataService) {
    _shareDataService.sharedData$.subscribe(sharedData => {
      this.headerData=sharedData;
    });
  }
}
