import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { CategoriesService } from './service/categories.service';

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  count?: any;
  PkFormSubCategoriesId?: any;
  SubCategoryName?: any;
  CategoryName?: any;
  CatTemplateCount?: any;
  SubCatTemplateCount?: any;
  PkFormCategoriesId?: any;
  // PkFormCategoriesId: any;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  count?: any;
  PkFormSubCategoriesId?: any;
  SubCategoryName?: any;
  level: number;
  expandable: boolean;
  CategoryName?: any;
  CatTemplateCount?: any;
  SubCatTemplateCount?: any;
  PkFormCategoriesId?: any;
}
/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor() {
    // this.initialize();
  }

  initialize(param) {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    console.log('sending param', param);
    const data = this.buildFileTree(param, 0);
    console.log(data);
    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(data, level: number): TodoItemNode[] {
    return data.map(cat => {
      console.log(cat);
      cat.item = cat.CategoryName || cat.SubCategoryName;
      cat.count = cat.CatTemplateCount || cat.SubCatTemplateCount || 0;
      if (cat.FSC) {
        cat.children = this.buildFileTree(cat.FSC, level + 1);
      }
      return cat;
    });
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ item: name } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
  providers: [ChecklistDatabase]
})
export class CategoriesComponent implements OnChanges {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  @Output() clickEvent = new EventEmitter();
  @Input('quickResponse') quickResponse;
  @Input('preSelect') preSelect;
  noSubmitBtn = false;
  selectedNode: any;
  submit: boolean;
  constructor(private _database: ChecklistDatabase, public categoryService: CategoriesService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.getCategories();
    _database.dataChange.subscribe((data) => {
      console.log('updating data', data);
      this.dataSource.data = data;
    });
    this.checklistSelection.changed.subscribe(d => {
      if (d.added.length === 0 && d.source.selected.length === 0) {
        this.clickEvent.emit([]);
      }
      if (this.noSubmitBtn) {
        this.clickEvent.emit(this.checklistSelection.selected);
      }
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.submit = false;
    if (simpleChanges.quickResponse && simpleChanges.quickResponse.currentValue) {
      this.noSubmitBtn = simpleChanges.quickResponse.currentValue.noSubmitBtn;
    }
    console.log(simpleChanges);
    if (simpleChanges.preSelect && simpleChanges.preSelect.currentValue) {
      this.treeControl.dataNodes.forEach(node => {
        if (+node.PkFormSubCategoriesId === +simpleChanges.preSelect.currentValue.catId) {
          this.checklistSelection.select(node);
        }
      });
    }
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => !_nodeData.item || !_nodeData.item.length;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.CategoryName || node.SubCategoryName;
    flatNode.count = node.count || 0;
    flatNode.level = level;
    if (node.PkFormCategoriesId) {
      flatNode.PkFormCategoriesId = node.PkFormCategoriesId;
    }
    if (node.PkFormSubCategoriesId) {
      flatNode.PkFormSubCategoriesId = node.PkFormSubCategoriesId;
    }
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: TodoItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    this.selectedNode = parentNode;
    console.log(this.selectedNode);
    this._database.insertItem(parentNode!, '');
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode, itemValue: string) {
    this.submit = true;
    if (itemValue && itemValue.length) {
      const nestedNode = this.flatNodeMap.get(node);
      this._database.updateItem(nestedNode!, itemValue);
      console.log(this.selectedNode);
      this.categoryService.SaveSubcategory({
        CategoryId: this.selectedNode.PkFormCategoriesId,
        SubCategoryName: itemValue,
        SubCategoryId: node.PkFormSubCategoriesId ? node.PkFormSubCategoriesId : 0,
      }).then(res => {
        this.submit = false;
        this.getCategories();
      });
    }
  }
  triggerClickEvent(submit?) {
    this.clickEvent.emit({ categories: this.checklistSelection.selected, onSubmit: submit });
  }
  getCategories() {
    this.categoryService.getCategories().then(categories => {
      if (categories && categories.length && categories[0].SubcategoryJson) {
        this._database.initialize(JSON.parse(categories[0].SubcategoryJson));
        setTimeout(() => this.treeControl.expandAll(), 0);
        // this.clickEvent.emit(this.dataSource.data[0]);
        console.log(this.dataSource);
        if (!this.noSubmitBtn) {
          this.todoItemSelectionToggle(this.treeControl.dataNodes[0]);
          this.triggerClickEvent(false);
        }
      }
    });
  }
}
