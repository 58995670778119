import { Component, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, Input } from '@angular/core';
import { FormTemplateService } from 'src/services/formtemplates.service';
import { Router } from '@angular/router';
import { ShareDataService } from 'src/services/sharedata.service';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit, OnChanges {
  @ViewChild('viewModalTrigger', { static: true }) viewModalTrigger;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // tslint:disable-next-line: no-input-rename
  @Input('linkForm') linkForm;
  headerData = {
    title: 'Dynamic Form Templates',
    description: 'Search your template using categories and tags...'
  };
  templates: any;
  tempData: any;
  categoriesSelected: any;
  bitMap: any;
  pageSize = 10;
  filteredlength = 20;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  linkMode: boolean;
  @Output() linkTemplate = new EventEmitter<any>();
  topSection: boolean;
  constructor(
    private _formtemplate: FormTemplateService,
    private _router: Router,
    public _shareDataService: ShareDataService
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.linkForm && changes.linkForm.currentValue) {
      this.linkMode = true;
    } else {
      this.linkMode = false;
    }
    this.topSection = false;
  }
  ngOnInit() {
    this._shareDataService.setDataObservable(this.headerData);
    // this.templates = this._formtemplate.GetTemplateList().subscribe(data => this.templates = data);
  }
  clone(data) { return JSON.parse(JSON.stringify(data)); }
  getRandomColor() {
    let color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  public gotoTemplateDetails(url, id) {
    id = id.split('.')[0];
    this._router.navigate([url, id]);
  }
  public viewData(tempData) {
    this._router.navigate(['/renderer', tempData.PkFormTemplateId]);
  }
  public editData(tempData) {
    this._router.navigateByUrl('/builder', { state: { templateId: tempData.PkFormTemplateId } });
  }
  GetTemplateList() {
    console.log('get list', {
      SubCategoryIdList : this.categoriesSelected,
      AppTenantBitMap: this.bitMap
    });
    this._formtemplate.GetTemplateList({
      SubCategoryIdList : this.getSelectedCategories(),
      AppTenantBitMap: this.bitMap
    }).subscribe(data => {
      this.templates = [];
      const res = JSON.parse(data[0].FormTemplateListJson);
      console.log(res);
      if (res && res.length) {
        this.templates = res;
      }
    });
  }
  getTemplates(event) {
    console.log(event);
    if (event.categories && event.categories.length) {
      this.filteredlength = this.getTemplatesLength(event.categories)[0];
      this.paginator.firstPage();
      const data = event.categories.map(temp => temp.PkFormSubCategoriesId);
      this.categoriesSelected = data.filter(d => d !== null);
      if (event.onSubmit) {
        this.GetTemplateList();
      }
    }
  }
  getActiveTenantId(event) {
    this.bitMap = event.bitMapSum;
    if (event.quickEvent) {
      this.GetTemplateList();
    }
  }
  public paginateTemplates(event) {
    console.log(event);
  }
  private getTemplatesLength(templates) {
    let count = 0;
    return templates.map((temp, i) => {
      if (i !== 0) {
        count += temp.count;
      }
      if (i === templates.length - 1) {
        return count;
      }
    }).filter(el => el !== undefined);
  }
  passTemplateId(params) {
    this.linkTemplate.emit({...params, checked: this.topSection});
  }
  deleteTemp(template) {
    this._formtemplate.DeleteTemplate({TemplateId: template.PkFormTemplateId})
    .subscribe(d => {
      this.templates = this.templates.filter(temp => temp.PkFormTemplateId !== template.PkFormTemplateId);
    });
  }
  getSelectedCategories() {
    const cats = this.categoriesSelected.filter(cat => cat !== undefined);
    if (cats && cats.length) {
      return cats.join(','); // sending only one cat without joining it with ,
    } else {
      return null;
    }
  }
}
