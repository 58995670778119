import { Injectable } from '@angular/core';

@Injectable()
export class RequestParams {
    keyvals: any;
    public ConstructRequest(Params: any): any {
        this.keyvals = [];
        for (const key in Params) {
            if (key === 'JSONData') {
                this.keyvals.push({ key: key, val: JSON.stringify(Params[key]) });
            } else {
                this.keyvals.push({ key: key, val: Params[key] });
            }
        }
        return { Params: this.keyvals };
    }
}
