import {Injectable} from '@angular/core';
import {ApiUrl} from "../utilities/server-urls";
import { DataFactoryService } from './api.service';
import { Observable } from 'rxjs';
import { RequestParams } from './request-params';

@Injectable()
export class FormRendererService {
    constructor(
        private _datafactory: DataFactoryService, private requestParams: RequestParams
    ) { }

    public GetFormMetaData(params): Observable<any> {
        return this._datafactory.post(ApiUrl.GetFormMetaData,params);
    }

    public SaveFormData(params): Observable<any> {
        return this._datafactory.post(ApiUrl.SaveFormData,params);
    }
    public GetTemplateById(params?) {
        return this._datafactory.post(ApiUrl.GetTemplateById, this.requestParams.ConstructRequest(params));
    }
}
