import {Injectable} from '@angular/core';
import {ApiUrl} from "../utilities/server-urls";
import { DataFactoryService } from './api.service';
import { RequestParams } from './request-params';
const templateList = [
    {
      PkFormTemplateId: 1,
      FkSubCategoryId: 1,
      FormTemplateName: "Sample1form",
      FormTemplateDesc: "Sample form to work on Dynamic forms",
      FkAppTenantId: "1"
    },
    {
      PkFormTemplateId: 2,
      FkSubCategoryId: 1,
      FormTemplateName: "Sample2Form",
      FormTemplateDesc: "Sample form 2 to work on templates",
      FkAppTenantId: "1"
    },
    {
      PkFormTemplateId: 3,
      FkSubCategoryId: 2,
      FormTemplateName: "Sample3Form",
      FormTemplateDesc: "Sample form 3 to work on templates",
      FkAppTenantId: "1"
    },
    {
      PkFormTemplateId: 4,
      FkSubCategoryId: 2,
      FormTemplateName: "Sample4Form",
      FormTemplateDesc: "Sample form 4 to work on templates",
      FkAppTenantId: "1"
    }
  ];
@Injectable()
export class FormTemplateService {
    constructor(
        private _datafactory: DataFactoryService, private requestParams: RequestParams
    ) { }

    public GetTemplateList(params?) {
        return this._datafactory.post(ApiUrl.GetTemplateList, this.requestParams.ConstructRequest(params));
        // return templateList;
    }
    public DeleteTemplate(params?) {
      return this._datafactory.post(ApiUrl.DeleteTemplate, this.requestParams.ConstructRequest(params));
      // return templateList;
  }
  public GetFormTypes(params?) {
    return this._datafactory.post(ApiUrl.GetFormTypes, this.requestParams.ConstructRequest(params));
    // return templateList;
}

}
