export let ServerHost: any;

ServerHost = {
    _ApiHost: 'https://api.forms365.in/api/',
    get ApiHost() {
        return this._ApiHost;
    },
    set ApiHost(value) {
        this._ApiHost = value;
    },
}

export let ApiUrl = {
    GetTemplateList: ServerHost.ApiHost + "Template/GetTemplateList",
    DeleteTemplate: ServerHost.ApiHost + "Template/InactiveDefaultFormTemplate",
    GetTemplateById: ServerHost.ApiHost + "Template/GetTemplateById",
    SaveSubcategory: ServerHost.ApiHost + "Template/SaveSubcategory",
    GetCategories: ServerHost.ApiHost + "Widgets/GetCategories",
    GetTenants: ServerHost.ApiHost + "Widgets/GetTenants",
    SaveDefaultTemplate: ServerHost.ApiHost + "Builder/SaveDefaultTemplate",
    SaveFormBuilderMetaData: ServerHost.ApiHost + "FormBuilder/SaveFormBuilderMetaData",
    GetFormMetaData: ServerHost.ApiHost + "FormRenderer/GetFormMetaData",
    SaveFormData: ServerHost.ApiHost + "FormRenderer/SaveFormData",
    SavedFormList: ServerHost.ApiHost + "SavedForm/SavedFormList",
    GetFormTypes: ServerHost.ApiHost + "Widgets/GetFormTypes"
}
