import { Injectable } from "@angular/core";
import { ApiUrl } from "../utilities/server-urls";
import { DataFactoryService } from "./api.service";
import { Observable } from "rxjs";

@Injectable()
export class SavedFormService {
  constructor(private _datafactory: DataFactoryService) {}

  public SavedFormList(): Observable<any> {
    return this._datafactory.get(ApiUrl.SavedFormList);
  }
}
