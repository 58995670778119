import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TenantsService } from './service/tenants.service';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss']
})
export class TenantsComponent implements OnInit, OnChanges {
  @Output() triggerClick = new EventEmitter();
  @Input('quickResponse') quickEvent;
  @Input('preSelect') preSelect;
  tenants: any;
  // public tenants = [
  //   {
  //     index: 0,
  //     id: 1,
  //     name: "QMS",
  //     selected: false
  //   },
  //   {
  //     index: 0,
  //     id: 1,
  //     name: "KYND",
  //     selected: false
  //   },
  //   {
  //     index: 0,
  //     id: 1,
  //     name: "Nybo",
  //     selected: true
  //   }
  // ];
  constructor(public tenantService: TenantsService) {
    console.log('tenants0');
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    console.log(simpleChanges);
    if (simpleChanges.preSelect && simpleChanges.preSelect.currentValue) {
      if (this.tenants && this.tenants.length) {
        const tenBitMaps = simpleChanges.preSelect.currentValue.tenantId.split(',');
        if (tenBitMaps && tenBitMaps.length) {
          tenBitMaps.forEach(bit => {
            const tenant = this.tenants.filter(ten => +ten.AppTenantBitMap === +bit);
            if (tenant && tenant.length) {
              this.triggerCheckEvent({ target: { checked: true } }, tenant[0], false);
            }
          });
        }
      }
    }
  }
  ngOnInit() {
    this.tenantService.GetTenants().subscribe(data => {
      this.tenants = data;
      // if (!this.quickEvent) { // uncomment if not to select at first.
        // tslint:disable-next-line: max-line-length
        // this.tenants.forEach((tenant, i) => this.triggerCheckEvent({target: {checked: true}}, tenant, i === (this.tenants.length - 1))); // uncomment if to select all at first
      this.tenants.forEach((tenant, i) => {
          if (tenant.PkAppTenantId === 1) {
            this.triggerCheckEvent({ target: { checked: true } }, tenant, true);
          }
        });
      // }
    });
  }
  triggerCheckEvent(event, tenant, quick?) {
    tenant['selected'] = event.target.checked;
    const selected = this.tenants.map(el => {
      if (el.selected) {
        return el.AppTenantBitMap;
      }
    });
    // tslint:disable-next-line: max-line-length
    setTimeout(() => this.triggerClick.emit({ bitMapSum: selected.filter(d => d !== undefined).reduce((a, b) => a + b, 0), quickEvent: quick }), 0);
  }

}
