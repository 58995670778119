import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-viewer-modal',
  templateUrl: './viewer-modal.component.html',
  styleUrls: ['./viewer-modal.component.scss']
})
export class ViewerModalComponent implements OnInit, OnChanges {
  @Input('tempData') tempData;
  items = [{title: 'Patient Exit Form'}, {title: 'Admission Form'}, {title: 'Arogya Bhadhratha'}];
  constructor() { }
  ngOnChanges(changes) {
    console.log(changes);
  }
  ngOnInit() {
  }

}
