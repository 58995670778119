import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  public tags = [
    {
      index: 0,
      id: 1,
      name: "Inbox",
      templateCount: 10,
      backgroundColor: "success"
    },
    {
      index: 0,
      id: 1,
      name: "Inbox",
      templateCount: 10,
      backgroundColor: "danger"
    },
    {
      index: 0,
      id: 1,
      name: "Inbox",
      templateCount: 10,
      backgroundColor: "dark"
    }
  ];
  constructor() { }

  ngOnInit() {
  }

}
