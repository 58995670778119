import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuilderComponent } from './forms/builder/builder.component';
import { RendererComponent } from './forms/renderer/renderer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TemplatesComponent } from './forms/templates/templates.component';
import { SavedFormsComponent } from './forms/saved-forms/saved-forms.component';


const routes: Routes =[
  { path: 'templates', component: TemplatesComponent },
  { path: 'savedforms', component: SavedFormsComponent },
  { path: 'builder', component: BuilderComponent },
  { path: 'renderer/:id', component: RendererComponent },
  { path: '',   redirectTo: '/templates', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
