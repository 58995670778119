import { Injectable } from '@angular/core';
import { DataFactoryService } from 'src/services/api.service';
import { ApiUrl } from 'src/utilities/server-urls';

@Injectable({
  providedIn: 'root'
})
export class TenantsService {

  constructor(private _datafactory: DataFactoryService) { }

  public GetTenants(params?) {
    return this._datafactory.post(ApiUrl.GetTenants, params);
  }
}
